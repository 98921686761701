import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import Sponsors from "images/sponsors.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.img((props) => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
]);

const DecoratorBlob = styled(SvgDotPattern)(() => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;

const Steps = tw.ul`mt-12`;
const Step = tw.li`mt-8 flex flex-col md:flex-row items-center md:items-start`;
const StepNumber = tw.div`font-semibold text-4xl leading-none text-gray-400`;
const StepText = tw.div`mt-3 md:mt-0 md:ml-6`;
const StepHeading = tw.h6`leading-none text-xl font-semibold`;
const StepHeadingHover = styled(StepHeading)`
  &:hover {
    color: palevioletred;
  }
`;
const StepDescription = tw.p`mt-3 max-w-xs leading-loose text-sm text-gray-600 font-medium`;

export default ({
  subheading = "Our Sponsors",
  heading = (
    <>
      Massive thanks to <span tw="text-primary-500">our sponsors.</span>
    </>
  ),
  imageSrc = Sponsors,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  imageDecoratorBlob = false,
  textOnLeft = true,
  steps = null,
  decoratorBlobCss = null,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  const defaultSteps = [
    {
      heading: "Baltimore Hackperspace",
      description:
        "Baltimore Hackerspace is a place for like-minded individuals to collaboratively develop their technical skills, and promote technical education in the community",
      url: "http://www.baltimorehackerspace.com/",
    },
    {
      heading: "Send Cut Send",
      description:
        "Send Cut Send provided our frame and weapon, and is our go-to lasercutting service in our personal projects as well as our combat robots.",
      url: "https://sendcutsend.com/",
    },
    {
      heading: "Five Star Roofing and Remodeling",
      description:
        "Your best choice for a full-service professional roofing and remodeling contractor",
      url: "https://www.5starroofingandrestoration.com/",
    },
    {
      heading: "BreachBits",
      description:
        "Connected Security is no joke, Breachbits offers automated and red-team pentesting of your online services to find any holes in your defenses before the blackhats do.",
      url: "https://www.breachbits.com/",
    },
    {
      heading: "New England Pine and Timber",
      description:
        "Doomba wouldn’t be Doomba without our chainsaw, and New England Pine and Timber equipped us with the carbide blades we need to cut through steel… maybe. For all your forestry needs though, New England Pine and Timber has you covered",
      url: "https://www.newenglandpine.com/",
    },
    {
      heading: "Spunspoon",
      description:
        "The only spoon to mount in your powertools for ultimate mixing performance - Launching soon!",
      url: "http://www.spunspoon.com",
    },
  ];

  if (!steps) steps = defaultSteps;

  return (
    <Container id="sponsors">
      <TwoColumn>
        <ImageColumn>
          <Image
            src={imageSrc}
            imageBorder={imageBorder}
            imageShadow={imageShadow}
            imageRounded={imageRounded}
          />
          {imageDecoratorBlob && <DecoratorBlob css={decoratorBlobCss} />}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Steps>
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepNumber>
                    {(index + 1).toString().padStart(2, "0")}
                  </StepNumber>
                  <StepText>
                    <StepHeadingHover as="a" href={step.url} target="_blank">
                      {step.heading}
                    </StepHeadingHover>
                    <StepDescription>{step.description}</StepDescription>
                  </StepText>
                </Step>
              ))}
            </Steps>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
