import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { ReactComponent as PriceIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as StarIcon } from "feather-icons/dist/icons/star.svg";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import Brice from "images/members/brice0.jpg";
import Brian from "images/members/brian0.jpg";
import Lou from "images/members/lou0.jpg";
import Rudy from "images/members/rudy0.jpg";
import Liz from "images/members/liz0.jpg";
import Phyllip from "images/members/phyllip.jpg";
import Sharon from "images/members/sharon.jpg";
import Chris from "images/members/chris.jpg";
const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)``;
const Controls = tw.div`flex items-center`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;

const CardSlider = styled(Slider)`
  ${tw`mt-16`}
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;
const Card = tw.div`h-full flex! flex-col sm:border max-w-sm sm:rounded-tl-4xl sm:rounded-br-5xl relative focus:outline-none`;
const CardImage = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center rounded sm:rounded-none sm:rounded-tl-4xl`,
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const RatingsInfo = styled.div`
  ${tw`flex items-center sm:ml-4 mt-2 sm:mt-0`}
  svg {
    ${tw`w-6 h-6 text-yellow-500 fill-current`}
  }
`;
const Rating = tw.span`ml-2 font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4`;
const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;

const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6`;
export default () => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const sliderSettings = {
    arrows: false,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        },
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  /* Change this according to your needs */
  const cards = [
    {
      imageSrc: Brice,
      title: "Brice Farrell",
      description:
        "Brice Farrell is the Captain of Doomba and a Lifelong aficionado of building dumb stuff. An electrical engineer by trade and training, he started in Battlebots by building the minibot Shorter Pounder for Battle Royale with Cheese, before founding Team Mammoth with Ricky Willems. He spent another two years with Mammoth, stepping back from leadership as the team got more serious and less dumb.  Currently, he leads both Mammoth and Baltimore Hackerspace - a nonprofit dedicated to teaching technical skills to the community and providing a place for like-minded individuals to work on their projects.",
      locationText: "Baltimore, Maryland, USA",
      rating: "5.0",
      primaryButtonText: "Learn More",
      primaryButtonUrl: "https://www.facebook.com/bricefarrell",
    },
    {
      imageSrc: Brian,
      title: "Brian Glatfelter",
      description:
        "Brian Glatfelter is the CAD guru and mechanical designer for team Doomba. Another officer who helps run Baltimore Hackerspace, Brian has helped out on various projects - including Mammoth - for years. In addition, he is a certified welder who takes care of all the tricky bits, and has helped teach the rest of the team to weld.",
      locationText: "Baltimore, Maryland, USA",
      rating: "4.9",
      primaryButtonText: "Learn More",
      primaryButtonUrl:
        "https://www.facebook.com/profile.php?id=100013059946840",
    },
    {
      imageSrc: Lou,
      title: "Thuong (Lou) Nguyen",
      description:
        "Thuong 'Lou' Nguyen is another former member of Mammoth. After going to college for Software Engineering, she joined up with Team Mammoth to learn fabrication skills. In just a few years she's gone from \"What's a socket wrench\" to \"Let me redo that weld for you, your's is ugly\" and milling parts out on the Bridgeport. And, most importantly, her dog is Adorable.",
      locationText: "Baltimore, Maryland, USA",
      rating: "5.0",
      primaryButtonText: "Learn More",
      primaryButtonUrl: "https://www.facebook.com/nhoKtenZ",
    },
    {
      imageSrc: Rudy,
      title: "Rudy Andriani",
      description:
        'Rudy Andriani is our Mechanical Engineer. A committed father and tinkerer, he\'s always planning out ways to make Doomba more reliable. He met the team at Baltimore Hackerspace and quickly earned the Nickname "FBI" - Federal Bot Inspector - due to his career with the government and snazzy sense of style.',
      locationText: "Baltimore, Maryland, USA",
      rating: "5.0",
      primaryButtonText: "Learn More",
      primaryButtonUrl: "https://www.facebook.com/rtadrian",
    },
    {
      imageSrc: Liz,
      title: "Liz Chavarria",
      description:
        "Liz Chavarria is our motivator and storyteller. We come to her with ideas for cool scenes, and she turns those scenes into a coherent story for our videos. Our application video was absolutely a team effort, but Liz is the one who elevated it to artwork, planning out the shots to turn what was a haphazard montage into a heartwarming saga. Liz started at Battlebots with Sporkinok,  joined Mammoth when she moved to Baltimore, and broke off with Brice and Lou to begin Doomba. Professionally, Liz runs various makerspaces, and has worked in Atlanta,  Baltimore, and Boston to help schools and nonprofits educate youth in the maker movement.",
      locationText: "Boston, USA",
      rating: "5.0",
      primaryButtonText: "Learn More",
      primaryButtonUrl: "https://www.facebook.com/LizChavarria55",
    },
    {
      imageSrc: Phyllip,
      title: "Phillipe Voyer",
      description:
        "Phillipe est un Français connu pour son amour du pain, en particulier des baguettes. On le voit souvent porter une baguette avec lui, allant même jusqu'à dormir avec une à ses côtés. Malgré son habitude étrange, Phillipe est un membre respecté de sa communauté et est connu pour sa nature gentille et généreuse. Il est passionné par la cuisine traditionnelle française et peut souvent être trouvé en train de cuisiner dans sa cuisine. Pendant son temps libre, il aime explorer la campagne, visiter les boulangeries locales et partager son amour du pain avec les autres.",
      locationText: "Quebec, Canada",
      rating: "5.0",
      primaryButtonText: "Learn More",
      primaryButtonUrl: "https://www.facebook.com/philippe.voyer.71",
    },
    {
      imageSrc: Sharon,
      title: "Sharon Chamberlain",
      description:
        "Team Lawyer: <br>In accordance with all relevant rules and regulations, this party simply undergoes a reasonable and responsible level of emotion colloquially perceived as bona fide contentment in the course of existing within the general proximity of the subject event.",
      locationText: "Baltimore, Maryland, USA",
      rating: "5.0",
      primaryButtonText: "Learn More",
      primaryButtonUrl: "https://www.facebook.com/SeeHast",
    },
    {
      imageSrc: Chris,
      title: "Christopher Chamberlain",
      description:
        "Also known as Porkchop, Chris is a former navy engineer, who fell in love with Combat Robots.  Chris has helped run  MACRO - Marylands antweight fighting league - for years, while competing with the dumbest robots he could design. Finally moving up to the big leagues, Chris now helps build the largest dumbest robot he could find. Specializing in sponsor relations and being a general wrench for hire, Chris makes sure we can afford our terrible decisions.",
      locationText: "Baltimore, Maryland, USA",
      rating: "5.0",
      primaryButtonText: "Learn More",
      primaryButtonUrl: "https://www.facebook.com/SeeHast",
    },
  ];
  console.log(cards);

  return (
    <Container id="members">
      <Content>
        <HeadingWithControl>
          <Heading>Team Members</Heading>
          <Controls>
            <PrevButton onClick={sliderRef?.slickPrev}>
              <ChevronLeftIcon />
            </PrevButton>
            <NextButton onClick={sliderRef?.slickNext}>
              <ChevronRightIcon />
            </NextButton>
          </Controls>
        </HeadingWithControl>
        <CardSlider ref={setSliderRef} {...sliderSettings}>
          {cards.map((card, index) => (
            <Card key={index}>
              <CardImage imageSrc={card.imageSrc} />
              <TextInfo>
                <TitleReviewContainer>
                  <Title>{card.title}</Title>
                  <RatingsInfo>
                    <StarIcon />
                    <Rating>{card.rating}</Rating>
                  </RatingsInfo>
                </TitleReviewContainer>
                <SecondaryInfoContainer>
                  <IconWithText>
                    <IconContainer>
                      <LocationIcon />
                    </IconContainer>
                    <Text>{card.locationText}</Text>
                  </IconWithText>
                </SecondaryInfoContainer>
                <Description>{card.description}</Description>
              </TextInfo>
              <PrimaryButton
                as="a"
                href={card.primaryButtonUrl}
                target="_blank"
              >
                {card.primaryButtonText}
              </PrimaryButton>
            </Card>
          ))}
        </CardSlider>
      </Content>
    </Container>
  );
};
